.divDeckGraph {
    position: relative;
    width: 100%;
    height: 100%;
}

.divDeckGraphCanvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.green {
    background: #00ff00;
}

.red {
    background: #ff0000;
    color: #ffffff;
}

.dummyWeightColor {
    color: red;
}